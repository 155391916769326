$background-primary: #f3f3f3;
$background-primary-dark: #212529;
$grey-dark: #575959;
$primary-color-hover: #21717f;
$grey-light: #f3f3f3;
$text-dark: #363636;
$text: #575959;
$text: #878787;
$secondary-color: #e98307;

:root {
  --primary-color: #257d8c;
  --primary-color-hover: #21717f;
  --primary-color-disabled: #80b4bc;
  --primary-color-background: #deebed;
  --secondary-color: #e98307;
  --secondary-color-background: #fbf1e6;
  // --grey-dark: #868686;
  --grey-medium: #b6b6b6;
  // --grey-light: #F3F3F3;
  --white: #ffffff;
  // --text-dark: #242424;
  --states-active: #92dc10;
  --states-cancel: #d50404;
}
$window-max-width: 1720px;

$background-primary-orange: #e98307;
$background-primary-dark: #212529;
$primary-color: #257d8c;

// Responsesive down size
$media-breakpoint-down-xs: 413.98px;
$media-breakpoint-down-sm: 573.98px;
$media-breakpoint-down-md: 767.98px;
$media-breakpoint-down-lg: 991.98px;
$media-breakpoint-down-xl: 1199.98px;
$media-breakpoint-down-xxl: 1699.98px;

// Responsive up size
$media-breakpoint-up-xs: 320px;
$media-breakpoint-up-sm: 576px;
$media-breakpoint-up-md: 768px;
$media-breakpoint-up-lg: 992px;
$media-breakpoint-up-xl: 1200px;
$media-breakpoint-up-xxl: 1600px;

$mobile-break: 1020px;

$shadow-elevation-low:
  0px 0.3px 0.5px $grey-dark,
  -0.1px 0.8px 1.2px $grey-dark,
  -0.1px 1.7px 2.6px $grey-dark;
$shadow-elevation-medium:
  0px 0.3px 0.5px $grey-dark,
  -0.1px 1.6px 2.4px $grey-dark,
  -0.2px 2.9px 4.4px $grey-dark,
  -0.3px 4.9px 7.4px $grey-dark,
  -0.6px 8.5px 12.8px;
$shadow-elevation-high:
  0px 0.3px 0.5px $grey-dark,
  -0.3px 4.1px 6.2px $grey-dark,
  -0.5px 7.4px 11.1px $grey-dark,
  -0.7px 10.6px 15.9px $grey-dark,
  -1px 14.1px 21.2px $grey-dark;

$box-shadow:
  inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.055),
  0 0 0 1px hsla(0, 0%, 0%, 0.05),
  0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
  0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
  0 3.5px 6px hsla(0, 0%, 0%, 0.02);
